@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');


* {
    font-family: "Roboto", sans-serif;
}

h1,h2,h3,h4,h5,h6{
    font-family: 'Secular One', sans-serif;
}

.table {
    text-align: center;
}

.table td,
.table th {
    white-space: nowrap;
}

.table thead th {
    padding: 1rem 0.5rem;
}

.table tbody tr{
    cursor: pointer;
}

.table tbody tr td {
    padding: 1.5rem .5rem;
}

.table .position {
    width: 5%;
}

.table .points,
.table .played,
.table .won,
.table .draw,
.table .lost,
.table .ga {
    width: 10%;
}

.table .badge-td {
    width: 5%;
}

.table .badge-td img {
    width: 100%;
    max-width: 1.875rem;
}

#forkongithub a {
    background: #ececec;
    color: #000;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    padding: 5px 40px;
    line-height: 2rem;
    position: relative;
    transition: 0.5s;
}

#forkongithub a:hover {
    background: #282a35;
    color: #ececec;
}

#forkongithub a::before,
#forkongithub a::after {
    content: "";
    width: 100%;
    display: block;
    position: absolute;
    top: 1px;
    left: 0;
    height: 1px;
    background: #d3d3d3;
}
#forkongithub a::after {
    bottom: 1px;
    top: auto;
}
@media screen and (min-width: 800px) {
    #forkongithub {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 200px;
        overflow: hidden;
        height: 200px;
        z-index: 9999;
    }
    #forkongithub a {
        width: 300px;
        position: absolute;
        top: 60px;
        left: -60px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
    }
}
